@import "../../sassStyles/variables";

.list-container {
  border-radius: 10px 10px 0 0;
  padding: 30px 10px;
  width: 100%;
  height: calc(100vh - 180px);
  position: absolute;
  top: 180px;
  overflow-y: auto;
  background-color: white;
}

.quantity {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .list-container {
    // border: 3px dotted purple;
    padding: 30px 5px 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .data-box {
      // border: 1px dotted red;
      width: 100%;
      max-width: 1240px;
      height: calc(100% - 40px);
      display: flex;
      justify-content: center;

      .render-results-box {
        height: 100%;
        overflow-y: auto;
        flex-grow: 1;
      }
    }
  }
}
