@import "../../sassStyles/variables";
@import "../../sassStyles/typography";

.header {
  width: 100%;
  height: auto;
  border-bottom: 1px solid $c-gray-80;
  background-color: #f7f1e3;
  position: relative;

  .header-container {
    margin: 0 auto;
    padding: 0 10px;
    width: inherit;
    width: 100%;
    max-width: 768px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logo {
    font-family: $font-title;
    font-weight: 500;
    font-size: 40px;
    margin: 10px 0;
    color: $color-primary;
  }

  .subtext {
    margin-bottom: 20px;
    font-size: 15px;
    text-align: center;
  }
}
