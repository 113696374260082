*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: white;
  font-family: $font-primary;
  color: #555;
}

section {
  padding: 35px 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.error-msg {
  text-align: center;
  color: $color-primary;
  margin-top: 16px;
}

.loader-overlay {
  // border: 1px dotted blue;
  position: absolute;
  width: 100vw;
  height: 50vh;
  display: grid;
  place-items: center;
  z-index: 4;

  .loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid $color-primary;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
