@import "../../sassStyles/variables";
@import "../../sassStyles/typography";

.form {
  border: 1px solid $c-gray-75;
  border-radius: 15px;
  overflow: hidden;
  background-color: white;
  margin-bottom: 40px;
  width: 260px;
  height: 30px;
  display: flex;
  align-items: center;

  &:hover {
    box-shadow: 2px 2px 5px $c-gray-85;
  }

  &:focus-within {
    border-color: $color-primary;
  }

  .search-button {
    border: none;
    margin-right: 10px;
    background: none;
    padding: 0 5px 0 10px;
    height: 35px;
    outline: none;
    display: grid;
    place-items: center;

    &:hover {
      background-color: $c-gray-95;
    }
  }

  .input-field {
    border: none;
    outline: none;
    width: 100%;
    height: 25px;
    font-size: 14px;
    font-family: $font-primary;

    &::placeholder {
      font-style: italic;
      color: $c-gray-60;
    }
  }
}
