@import "../sassStyles/variables";

.card {
  margin: auto;
  padding: 10px 0;
  width: 100%;
  max-width: 400px;
  height: 140px;
  border-bottom: 2px solid $c-gray-75;
  display: flex;

  &:hover {
    &:hover {
      box-shadow: 5px 5px 10px $c-gray-80;
    }
  }

  img {
    margin-right: 10px;
    width: 102px;
    height: 102px;
    border-radius: 10px;
  }

  &__text-box {
    padding-right: 5px;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    font-size: 18px;
    color: $color-primary;
  }

  &__btn {
    padding: 10px 0;
    cursor: pointer;
    background-color: white;
    border: 1px solid $c-gray-60;
    border-radius: 5px;
    transition: background-color 0.3s ease-in;

    &:hover {
      background-color: black;
      color: white;
    }
  }
}

// @media screen and (min-width: 768px) {
//   .card {
//
//   }
// }
