// grayscale

$c-white: #ffffff;
$c-gray-98: #f3f3f3;
$c-gray-95: #ededed;
$c-gray-90: #e3e3e3;
$c-gray-85: #d9d9d9;
$c-gray-80: #cccccc;
$c-gray-75: #c0c0c0;
$c-gray-70: #b0b0b0;
$c-gray-60: #999999;
$c-gray-50: #808080;
$c-gray-45: #727272;
$c-gray-40: #696969;
$c-gray-30: #4d4d4d;
$c-gray-20: #363636;
$c-gray-15: #222222;
$c-gray-10: #171717;
$c-black: #000000;

// colors
$color-primary: #e74c3c;
