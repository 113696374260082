@import "../../sassStyles/variables";

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 425px;
  height: 100vh;
  background-color: white;

  .top-bar {
    width: 100%;
    height: 50px;
    background-color: white;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 18px;

    &__back-btn {
      display: flex;
      justify-content: start;
      align-items: center;
      transition: color 0.2s ease-in;

      &:hover {
        cursor: pointer;
        color: $color-primary;
      }
    }
  }

  .chevron-left {
    font-size: 30px;
  }

  .modal {
    width: 100%;
    height: calc(100vh - 50px);
    overflow-y: auto;

    * {
      display: block;
    }

    &__image {
      margin-bottom: 16px;
      width: 100%;
      height: 40vh;
      object-fit: cover;
      border-radius: 5%;
    }

    &__text-box {
      padding: 0 10px;
    }

    &__title {
      margin-bottom: 16px;
      color: $color-primary;
      font-size: 22px;
    }

    &__sub-text {
      font-style: italic;
      font-size: 14px;
    }

    &__ingredients-container {
      margin: 40px 0;
      padding: 16px 0;
      border-top: 1.5px solid $c-gray-70;
      border-bottom: 1.5px solid $c-gray-70;
    }

    &__instructions {
      white-space: pre-line;
      font-size: 14px;
    }

    &__btn {
      margin: 30px 0;
      padding: 10px 0;
      width: 100%;
      text-align: center;
      cursor: pointer;
      background-color: black;
      border: 1px solid $c-gray-60;
      border-radius: 5px;
      color: white;
      transition: background-color 0.3s ease-in;

      &:hover {
        background-color: $color-primary;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .modal-container {
    position: relative;
    height: 100%;
    max-width: 60%;

    .top-bar {
      display: none;
    }

    .modal {
      height: 100%;
    }
  }
}
